import React, { useRef, useState } from 'react';
import { Query } from 'react-apollo';
import { getOperationName } from '@apollo/client/utilities';

import { SENTRY_ORIGINS, captureError } from '../../lib/sentry';
import IbePropTypes from '../../IbePropTypes';

const SafeQuery = ({ fallback, children, ...rest }) => {
  const [error, setError] = useState(null);
  // Ref to make sure we don't log the same error multiple times
  const capturedRef = useRef(false);

  return (
    <Query {...rest} onError={setError} context={{ handled: true }}>
      {(...args) => {
        if (error) {
          const queryName = getOperationName(rest?.query) ?? 'unknown';
          if (capturedRef.current === false && error) {
            captureError(error, SENTRY_ORIGINS.APOLLO, {
              query: queryName,
            });
            capturedRef.current = true;
          }
          return fallback;
        }
        return children(...args);
      }}
    </Query>
  );
};

SafeQuery.propTypes = {
  fallback: IbePropTypes.reactNode,
  children: IbePropTypes.children.isRequired,
};

SafeQuery.defaultProps = {
  fallback: <></>,
};

export default SafeQuery;
