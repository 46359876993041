import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql, withApollo } from 'react-apollo';
import { withTheme } from 'styled-components';

import FlexTable, { FlexCell, FlexRow } from '../ui/FlexTable';
import ButtonBrand from '../ui/Button/ButtonBrand.style';
import Icon from '../ui/Icon';

import { Toggle } from '../ui/List/List.style';

import formatAddress from '../../lib/formatAddress';
import getCampsiteType from '../../lib/campsiteTypes';
import { ids } from '../../config/campsiteTypes';
import testingAttr from '../../lib/testingAttr';
import { generateDateRange, filterDatesByMonth } from '../../lib/helpers/tours';

import Note from '../Note';
import Price from '../Price';
import { dictionaryItem, getDictionaryItem } from '../../hocs/withDictionary';

import svgOffer from '../../static/images/icons/Offer.svg';

import CampsiteFeatureIcon from '../CampsiteFeature/CampsiteFeatureIcon';
import CampsiteFeatureQuery from '../CampsiteFeature/CampsiteFeatureQuery';

import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import PLACE_EVENT_TYPES, { SINGLE_EVENT_TYPE } from '../../config/eventTypes';
import arrowDownSVG from '../../static/images/icons/ArrowDown.svg';
import uniqueObjectsByKey from '../../lib/uniqueObjectsByKey';

import {
  Description,
  SubHeading,
  Container,
  Featured,
  Features,
  Footer,
  Header,
  Image,
  Img,
  LeftCol,
  LowAvailability,
  MissingImg,
  OffersNote,
  Pricing,
  Rating,
  Row,
  ItemTitle,
  USP,
  RightCol,
  ToursActions,
  ListingEventDistanceContainer,
  Distance,
} from './ListingItem.style';
import ListingEventTypes from './ListingEventTypes';
import { dataLayerManager } from '../../lib/dataLayer';
import IbePropTypes from '../../IbePropTypes';
import { metersToMilesFormatter } from '../../lib/helpers/distance';
import { Text } from '../ui';
import { campsiteImageThumbKeys, mapAlternativeImages } from '../Picture/pictureHelper';
import Picture from '../Picture';

class ListingItem extends Component {
  static propTypes = {
    activePin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    address: PropTypes.shape({}),
    client: PropTypes.shape(IbePropTypes.client).isRequired,
    eventType: PropTypes.number,
    offers: PropTypes.arrayOf(PropTypes.shape({})),
    configuration: PropTypes.shape(IbePropTypes.configuration),
    currentCampsiteId: PropTypes.string,
    hasLowAvailability: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    index: PropTypes.number,
    gallery: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.shape({ 1: PropTypes.shape(IbePropTypes.optimizedImage) }),
    ]),
    name: PropTypes.string.isRequired,
    numberOfReviews: PropTypes.number,
    memberPrice: PropTypes.number,
    onLoadImage: PropTypes.func,
    onResultClick: PropTypes.func.isRequired,
    rating: PropTypes.number,
    features: PropTypes.arrayOf(PropTypes.shape({})),
    topFeatures: PropTypes.arrayOf(PropTypes.shape()),
    type: PropTypes.number,
    usp: PropTypes.string,
    loadingCampsites: PropTypes.bool.isRequired,
    loadingPrices: PropTypes.bool.isRequired,
    standardPrice: PropTypes.number,
    isFeatured: PropTypes.bool.isRequired,
    theme: PropTypes.shape({
      COLOR_WHITE: PropTypes.string,
      COLOR_AMBER_RANGE: PropTypes.string,
    }),
    placeEvent: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.event)),
    isOpen: PropTypes.bool,
    isTours: PropTypes.bool,
    isUkItinerary: PropTypes.bool,
    tour: PropTypes.shape(IbePropTypes.tour),
    showDates: PropTypes.bool,
    departureMonth: PropTypes.string,
    distanceMeters: PropTypes.number,
    showEvents: PropTypes.bool,
  };

  static defaultProps = {
    activePin: null,
    address: {
      addressLines: [],
    },
    configuration: {
      campsiteTypes: [],
    },
    currentCampsiteId: '',
    eventType: PLACE_EVENT_TYPES.TOURING.id,
    hasLowAvailability: false,
    index: 0,
    memberPrice: null,
    numberOfReviews: null,
    onLoadImage: () => {},
    offers: [],
    rating: null,
    standardPrice: 0,
    features: [],
    topFeatures: [],
    theme: {
      COLOR_WHITE: '',
      COLOR_AMBER_RANGE: '',
    },
    type: null,
    usp: '',
    gallery: {},
    placeEvent: [],
    isOpen: true,
    isTours: false,
    isUkItinerary: false,
    tour: {},
    showDates: false,
    departureMonth: '',
    distanceMeters: null,
    showEvents: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      expand: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.loadingCampsites !== nextProps.loadingCampsites) return true;
    if (this.props.loadingPrices !== nextProps.loadingPrices) return true;
    if (this.props.currentCampsiteId !== nextProps.currentCampsiteId) return true;
    if (this.props.memberPrice !== nextProps.memberPrice) return true;
    if (this.props.standardPrice !== nextProps.standardPrice) return true;
    if (this.props.tour?.id !== nextProps.tour?.id) return true;
    if (this.props.activePin !== nextProps.activePin) return true;
    if (this.props.showDates !== nextProps.showDates) return true;
    if (this.state.expand !== nextState.expand) return true;
    return false;
  }

  handleProductClick(campsite) {
    const { isUkItinerary, isTours } = this.props;
    const data = { ...this.props };
    let categoryType = dataLayerManager.category.SITES;
    if (isUkItinerary) {
      categoryType = dataLayerManager.subCategory.UK_ITINERARY;
      data.subCategory = categoryType;
    }
    if (isTours) {
      categoryType = dataLayerManager.subCategory.EUROPEAN_TOURS;
      data.subCategory = categoryType;
    }
    dataLayerManager.pushProductClick(
      categoryType, data, this.props.index,
    );

    this.props.onResultClick(campsite);
  }

  onLoadImage = () => {
    this.props.onLoadImage(this.props.id);
  }

  renderImage = (galleryItem) => {
    if (!galleryItem) {
      return (
        <MissingImg
          role="img"
          aria-label={this.props.name}
          url="/static/images/missing-image.svg"
          {...testingAttr(`search-results-campsite__listing-item-${this.props.index}__image`)}
        />
      );
    }

    const galleryImage = mapAlternativeImages(
      galleryItem.url,
      galleryItem.alternativeFormats,
      campsiteImageThumbKeys,
    );

    if (!galleryImage) {
      // maintain compatibility with components that don't utilize new optimized image structure
      return <Img
        alt={this.props.name}
        aria-label={this.props.name}
        src={galleryItem || ''}
        onLoad={this.onLoadImage}
        loading="lazy"
        {...testingAttr(`search-results-campsite__listing-item-${this.props.index}__image`)}
      />;
    }

    return (
      <Picture image={galleryImage}>
        <Img
          alt={this.props.name}
          aria-label={this.props.name}
          src={galleryImage.baseUrl || ''}
          onLoad={this.onLoadImage}
          loading="lazy"
          {...testingAttr(`search-results-campsite__listing-item-${this.props.index}__image`)}
        />
      </Picture>
    );
  }

  render() {
    const {
      address, id, hasLowAvailability, name, offers, eventType, type,
      placeEvent, isTours, client, tour, showDates, departureMonth,
      distanceMeters, configuration, showEvents,
    } = this.props;

    const {
      showALongWayWhenGreaterThanDistanceDecimal,
      showOneDecimalPlaceWhenLessThanDistanceDecimal,
    } = configuration?.configurableValues;

    const campsite = {
      address, id, name, offers,
    };

    const loadingCampsitesString = this.props.loadingCampsites.toString();

    const campsiteType = getCampsiteType(
      this.props.configuration.campsiteTypes,
      isTours ? ids.OVERSEAS_TOUR : this.props.type,
    );

    const noRatingCampsiteTypes = [
      ids.UK_AFFILIATED_SITES,
      ids.OVERSEAS_TOUR,
    ];

    const noPriceCampsiteTypes = [
      ids.UK_AFFILIATED_SITES,
      ids.CERTIFICATED_LOCATIONS,
      ids.OVERSEAS_TOUR,
    ];

    const showRating = this.props.rating !== null &&
      noRatingCampsiteTypes.includes(campsiteType.id) === false;

    const abilityToShowPrice
      = noPriceCampsiteTypes.includes(campsiteType.id) === false || !!tour?.fromPrice;
    const hasPrice = !!(this.props.memberPrice || tour?.fromPrice) && this.props.isOpen;

    const toursNightsSuffix = getDictionaryItem(client, 'ListingItem__ToursNightsSuffix__Text');
    const priceSuffix = getDictionaryItem(client, 'ListingResult__PriceSuffix__Text');

    const galleryEventType = type === ids.OVERSEAS_SITE ? SINGLE_EVENT_TYPE.id : eventType;
    const galleryKey = !Array.isArray(this.props.gallery) ? galleryEventType : 0;

    const filteredTourDates = filterDatesByMonth(tour?.departureDates, departureMonth);
    const tourDatesToShow = filteredTourDates.length ? filteredTourDates : tour?.departureDates;

    const isSelected = this.props.activePin?.toString() === this.props.id?.toString();

    return (
      <Container
        className={isSelected ? 'selected' : ''}
        loading={loadingCampsitesString}
        featured={this.props.isFeatured}
        selected={isSelected}
        selectedColor={campsiteType.color}
        onClick={isTours ? undefined : () => this.handleProductClick(campsite)}
        {...testingAttr(`search-results-campsite__listing-item-${this.props.index}`)}
      >
        {this.props.isFeatured &&
          <Featured>Featured Site</Featured>
        }
        <Row wrap={isTours ? 'nowrap' : ''}>
          <LeftCol onClick={!isTours ? undefined : () => this.handleProductClick(campsite)}>
            <Image>
              {this.renderImage(this.props.gallery?.[galleryKey])}
            </Image>
            <Note
              backgroundColor={campsiteType.color}
              align="center"
              color={this.props.theme.COLOR_WHITE}
              text={campsiteType.text}
            />
            {(showRating && !isTours) &&
              <Rating
                hidden="desktop"
                noOfReviews={this.props.numberOfReviews}
                rating={this.props.rating}
                size="0.625rem"
                style={{ margin: 'auto', padding: '0.25rem 0' }}
                {...testingAttr(`search-results-campsite__listing-item-${this.props.index}__rating`)}
              />
            }
            {!isTours && showEvents &&
              <ListingEventTypes events={placeEvent} />
            }
          </LeftCol>
          <RightCol onClick={!isTours ? undefined : () => this.handleProductClick(campsite)}>
            <Header>
              <ItemTitle {...testingAttr(`search-results-campsite__listing-item-${this.props.index}__title`)}>{this.props.name}</ItemTitle>
              {showRating && !isTours &&
                <Rating
                  hidden="mobile"
                  noOfReviews={this.props.numberOfReviews}
                  rating={this.props.rating}
                  size="0.625rem"
                  style={{ marginLeft: 'auto', padding: '0.25rem 0' }}
                  {...testingAttr(`search-results-campsite__listing-item-${this.props.index}__rating`)}
                />
              }
            </Header>
            {isTours &&
              <SubHeading>
                <span>{tour?.numberOfNights} {toursNightsSuffix}</span>
              </SubHeading>}
            <Description
              full={isTours}
              {...testingAttr(`search-results-campsite__listing-item-${this.props.index}__address`)}
            >
              {isTours ? tour?.description :
                formatAddress(this.props.address)}
            </Description>
            <Features>
              {uniqueObjectsByKey((this.props.topFeatures || this.props.features), 'iconUrl')
                .map((feature, index) => {
                  if (index >= 10) return null;
                  return (
                    <CampsiteFeatureQuery key={feature.id} id={feature.id}>
                      {campsiteFeatureProps => (
                        <CampsiteFeatureIcon
                          {...campsiteFeatureProps}
                          name={feature.name}
                          hideNoIcon
                        />
                      )}
                    </CampsiteFeatureQuery>
                  );
                })}
            </Features>
            <USP>{this.props.usp}</USP>
            <Footer>
              {!!(this.props.offers && !isTours && this.props.offers.length) &&
                <OffersNote
                  color={this.props.theme.COLOR_AMBER_RANGE}
                  hasIcon
                  icon={svgOffer}
                  dictionary={dictionaryItem('ListingItem', 'OffersAvailable')}
                />
              }
              {abilityToShowPrice &&
                <Pricing align={isTours ? 'left' : 'right'}>
                  {hasPrice ? (
                    <Price
                      amount={this.props.memberPrice || tour?.fromPrice}
                      prefix={getDictionaryItem(client, 'ListingItem__ToursPricePrefix__Text')}
                      testingId={`search-results-campsite__listing-item-${this.props.index}__price`}
                      suffix={isTours ? (tour?.fromPriceSuffix || '').toLowerCase() : priceSuffix}
                    />
                  ) : (
                    <>
                      {this.props.loadingPrices ? (
                        <Text size="1rem" dictionary={dictionaryItem('ListingItem', 'PriceLoading')} />
                      ) : (
                        <>
                          {this.props.isOpen
                            ? <Text size="1rem" dictionary={dictionaryItem('ListingItem', 'PriceUnavailable')} />
                            : <Text size="1rem" dictionary={dictionaryItem('ListingItem', 'SiteClosed')} />
                          }
                        </>
                      )}
                    </>
                  )}
                </Pricing>
              }
              {hasLowAvailability &&
                <LowAvailability dictionary={dictionaryItem('ListingItem', 'LowAvailability')} />
              }
            </Footer>
          </RightCol>
        </Row>
        <Row>
          <ListingEventDistanceContainer>
            <Distance>
              {distanceMeters && metersToMilesFormatter(
                distanceMeters,
                showALongWayWhenGreaterThanDistanceDecimal,
                showOneDecimalPlaceWhenLessThanDistanceDecimal,
                getDictionaryItem(client, 'ListingResult__DistanceMiles__Text'),
                getDictionaryItem(client, 'ListingResult__DistanceLongWay__Text'),
              )}
            </Distance>
          </ListingEventDistanceContainer>
        </Row>
        <Row>
          {isTours &&
            <ToursActions>
              <ButtonBrand
                block
                flat
                marginBottom="0.5rem"
                size="small"
                type="button"
                dictionary={dictionaryItem('SearchResults', 'MoreDetails')}
                onClick={() => this.handleProductClick(campsite)}
              />
              {showDates && tourDatesToShow?.length > 0 && (
                <>
                  <Toggle
                    block
                    bordered
                    onClick={() => this.setState({ expand: !this.state.expand })}
                    size="small"
                  >
                    <span>{getDictionaryItem(
                      client,
                      `SearchResults__${this.state.expand ? 'HideDates' : 'ShowDates'}__Button`,
                    )}
                    </span>
                    <Icon
                      icon={arrowDownSVG}
                      size="0.625rem"
                      style={{ marginLeft: '0.5rem' }}
                    />
                  </Toggle>
                  {this.state.expand &&
                    <FlexTable>
                      {tourDatesToShow
                        .map((departureDate) => (
                          <FlexRow invert key={departureDate}>
                            <FlexCell small>
                              {generateDateRange(departureDate, tour?.numberOfNights)}
                            </FlexCell>
                          </FlexRow>
                        ))}
                    </FlexTable>
                  }
                </>
              )}
            </ToursActions>
          }
        </Row>
      </Container>
    );
  }
}

export default compose(
  withTheme,
  withApollo,
  graphql(GET_CONFIGURATION, {
    props: ({ data }) => ({
      configuration: data.configuration,
    }),
  }),
)(ListingItem);
