import React from 'react';
import PropTypes from 'prop-types';
import {
  CampsiteIconsContainer,
  CampsiteIcon,
} from './ListingResult.style';
import ToursEvent from '../../static/images/icons/ToursEvent.svg';
import CampingEvent from '../../static/images/icons/CampingEvent.svg';
import GlampingEvent from '../../static/images/icons/GlampingEvent.svg';
import IbePropTypes from '../../IbePropTypes';
import PLACE_EVENT_TYPES,
{ SINGLE_EVENT_TYPE } from '../../config/eventTypes';

const ListingEventTypes = ({ events }) => {
  const hasEventType = (eventType) => !!events.find(
    (eventItem) => eventItem.eventType === eventType,
  );
  return (
    <CampsiteIconsContainer marginTop wrap="true">
      {(hasEventType(PLACE_EVENT_TYPES.TOURING.id) ||
        hasEventType(SINGLE_EVENT_TYPE.id)) && <CampsiteIcon
          icon={ToursEvent}
          largeIcon
        />}
      {hasEventType(PLACE_EVENT_TYPES.CAMPING.id) && <CampsiteIcon
        icon={CampingEvent}
      />}
      {hasEventType(PLACE_EVENT_TYPES.GLAMPING.id) && <CampsiteIcon
        icon={GlampingEvent}
      />}
    </CampsiteIconsContainer>
  );
};

ListingEventTypes.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.event)),
};

ListingEventTypes.defaultProps = {
  events: [],
};

export default ListingEventTypes;
