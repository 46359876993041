import React from 'react';
import PropTypes from 'prop-types';

import testingAttr from '../../lib/testingAttr';

import formatPrice from '../../lib/formatPrice';
import { LoadingSpinner } from '../ui/Loading';
import StyledPrice, { PriceAmount, PriceText } from './Price.style';

import theme from '../../styles/config/theme';

const defaultProps = {
  amount: null,
  color: theme.COLOR_TEXT_BODY,
  currency: '£',
  prefix: '',
  size: '1.25rem',
  suffix: 'Per Night',
  testingId: null,
  loading: false,
  hidePence: false,
};

const propTypes = {
  amount: PropTypes.number,
  color: PropTypes.string,
  currency: PropTypes.string,
  prefix: PropTypes.string,
  size: PropTypes.string,
  suffix: PropTypes.string,
  testingId: PropTypes.string,
  loading: PropTypes.bool,
  hidePence: PropTypes.bool,
};

function Price(props) {
  function Amount() {
    if (props.amount === 0) return null;
    return (
      props.amount ?
        <span>{formatPrice(props.amount, props.currency, props.hidePence)}</span> :
        <span>N/A</span>
    );
  }

  return (
    <StyledPrice color={props.color}>
      {props.prefix &&
        <PriceText style={{ marginRight: '0.25rem' }}>{props.prefix}</PriceText>
      }
      <PriceAmount size={props.size} {...testingAttr(props.testingId)}>
        <Amount />
      </PriceAmount>
      <PriceText style={{ marginLeft: '0.25rem' }}>{props.suffix}</PriceText>
      {props.loading && <LoadingSpinner size="1rem" inline />}
    </StyledPrice>
  );
}

Price.defaultProps = defaultProps;
Price.propTypes = propTypes;

export default Price;
