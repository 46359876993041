import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose, withApollo } from 'react-apollo';
import { withRouter } from 'next/router';
import { withProps } from 'recompose';
import { format } from 'date-fns';

import scrollToTop from '../../lib/scrollToTop';
import parseQueryFromRouter from '../../lib/helpers/sites';
import { parseBounds } from '../../lib/query';
import updateRouterQuery, { parseQuery, redirectFromWidget } from '../../lib/updateRouterQuery';

import { dictionaryItem } from '../../hocs/withDictionary';

import CampsiteResults from './CampsiteResults';
import CampsiteSearchForm from './CampsiteSearchForm';
import Filters from '../Filters';
import ItineraryModal from '../Filters/SuggestedUkItinerary/ItineraryModal';
import FixedInfoWindow from '../CampsiteMap/FixedInfoWindow';
import DrawerInfoWindow from '../CampsiteMap/DrawerInfoWindow';

import RenderIcon from '../Listing/RenderIcon';
import {
  Wrapper, CampsiteSearchFooter, Actions,
} from './CampsiteSearch.style';
import { Text } from '../SearchSummary/SearchSummary.style';
import SearchForm from '../SearchForm/SearchForm';
import SiteNightVoucher from '../SiteNightVoucher';
import EventsButtonsWrapper from '../SearchForm/Campsites/EventsButtonsWrapper';
import DisplayToRoot from '../DisplayToRoot';

import { ToggleMap } from '../Listing/Listing.style';
import { Title } from './CampsiteSearchForm.style';
import { ToursButton, ToursButtons } from '../SearchForm/Tours/ToursButton.style';

import { GET_CURRENT_CAMPSITE } from '../../config/graphql';
import UPDATE_CURRENT_CAMPSITE from '../../config/graphql/updateCurrentCampsite';
import GET_SUGGESTED_UK_ITINERARIES from '../../config/graphql/getSuggestedUkItineraries';
import GET_CONFIGURATION from '../../config/graphql/getConfiguration';

import { DATE_FORMAT_DEFAULT } from '../../config/locale';
import PLACE_EVENT_TYPES, {
  HIDE_FROM_CAMPING_TYPES,
} from '../../config/eventTypes';
import routes from '../../constants/routes';
import { dateInputValidation as dateInputValidationRegex } from '../../lib/regexes';
import { formatToHyphenFormat } from '../../lib/format';
import { dataLayerManager } from '../../lib/dataLayer';
import { TOURS_SORT_TYPES, nearestTours } from '../../lib/helpers/tours';
import IbePropTypes from '../../IbePropTypes';
import { MY_LOCATION_SEARCH_KEY, searchByType } from '../../constants/search';
import { startTiming, types as timingTypes } from '../../lib/timings';
import { hasStorageItemList, setStorageItemList } from '../../lib/storage';
import { LOCATION_SEARCH_COORDINATES_IN_SESSION } from '../../lib/constants';
import { CAMPSITE_SORT_TYPES } from '../../lib/campsiteSearch';
import { getBoundsFromCenterAndZoom, getUserLocationAsync } from '../CampsiteMap/helpers';
import { USER_LOCATION_PERMISSIONS } from '../../lib/location';
import PermissionsModal from '../CampsiteMap/PermissionsModal';
import { fixedInfoWindowClass } from '../CampsiteMap/FixedInfoWindow.style';
import withResizeObserver from '../../hocs/withResizeObserver';

// switch used to only trigger an impression when price and listing are updated.
let waitForCampsiteListAndPriceToUpdate = false;
let impressionDebounceTimeoutId;
const IMPRESSION_DEBOUNCE_TIME = 2000; // ms of wait before impression is made.

class CampsiteSearch extends Component {
  static propTypes = {
    configuration: PropTypes.shape(IbePropTypes.configuration),
    data: PropTypes.shape({
      currentCampsite: PropTypes.shape(IbePropTypes.campsite),
    }),
    formRef: PropTypes.shape({}),
    isMobile: PropTypes.bool.isRequired,
    basket: PropTypes.bool.isRequired,
    error: PropTypes.shape({}),
    goToTop: PropTypes.func,
    isMobileMapOpen: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    onClear: PropTypes.func.isRequired,
    onQueryChange: PropTypes.func,
    onResultClick: PropTypes.func.isRequired,
    onSubmitSearchForm: PropTypes.func.isRequired,
    query: PropTypes.shape(IbePropTypes.query).isRequired,
    searchBy: PropTypes.string,
    updateSearchBy: PropTypes.func.isRequired,
    toggleBasket: PropTypes.func.isRequired,
    toggleMobileMap: PropTypes.func.isRequired,
    refMap: PropTypes.shape({}),
    showResults: PropTypes.bool.isRequired,
    onProductTypeMismatch: PropTypes.func,
    defaultTypes: PropTypes.arrayOf(PropTypes.number),
    router: PropTypes.shape(IbePropTypes.router).isRequired,
    quote: PropTypes.shape(IbePropTypes.quote),
    suggestedUkItineraries: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.ukItinerary)),
    tours: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.tour)),
    locationPermission: PropTypes.string,
    location: PropTypes.shape(IbePropTypes.coordinates),
    requestLocation: PropTypes.func,
    client: PropTypes.shape(IbePropTypes.client),
    resizeAdjustmentAmount: PropTypes.number.isRequired, //  by withResizeObserver HOC
  }

  static defaultProps = {
    configuration: null,
    data: {
      currentCampsite: {
        lat: null,
        lon: null,
        name: '',
        zoomLevel: null,
        type: null,
      },
    },
    formRef: {},
    goToTop: () => { window.scrollTo(0, 0); },
    error: null,
    loading: false,
    onQueryChange: () => { },
    searchBy: undefined,
    refMap: null,
    onProductTypeMismatch: null,
    defaultTypes: [],
    quote: null,
    suggestedUkItineraries: [],
    tours: [],
    locationPermission: USER_LOCATION_PERMISSIONS.NOT_KNOWN,
    location: {
      lat: null,
      lng: null,
    },
    requestLocation: () => { },
    client: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      campsiteId: this.props.query.campsiteId,
      campsiteName: this.props.query.campsiteName,
      ne_lat: this.props.query.ne_lat,
      ne_lng: this.props.query.ne_lng,
      sw_lat: this.props.query.sw_lat,
      sw_lng: this.props.query.sw_lng,
      location: this.getLocationToInitializeState(this.props.query.location),
      toggle: {
        filters: false,
        search: false,
      },
      itineraryModal: null,
      activeEventId: Number(this.props.query.eventType) ||
        PLACE_EVENT_TYPES.TOURING.id,
      departureMonth: this.props.query.departureMonth,
      duration: this.props.query.duration,
      coords: null,
      isDrawerVisible: true,
      showGeolocationModal: false,
    };
  }

  componentDidMount() {
    const eventType = Number(this.props.query.eventType);
    if (eventType === PLACE_EVENT_TYPES.CAMPING.id) {
      const singleTypeQuery = typeof this.props.query.types === 'string';
      const newQuery = {
        ...this.props.router.query,
        types: singleTypeQuery ? this.props.query.types :
          this.props.query.types?.filter(
            (campsiteType) => !HIDE_FROM_CAMPING_TYPES.includes(campsiteType),
          ),
      };

      this.props.onQueryChange(newQuery);
      updateRouterQuery(routes.sites, newQuery);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      departureMonth, duration, ukItinerary, activePin, eventType,
    } = this.props.query;
    if (prevProps.query.departureMonth !== departureMonth) {
      this.handleDepartureMonthSelect(departureMonth);
    }
    if (prevProps.query.duration !== duration) {
      this.handleDurationSelect(duration);
    }
    if (ukItinerary && (prevProps.query.ukItinerary !== ukItinerary)) {
      this.setImpressionsSent(false);
    }
    if (prevProps.query.activePin !== activePin) {
      this.handleToggleDrawer();
    }
    if (prevProps.query.eventType !== eventType) {
      this.handleActiveQueryTypeUpdate(eventType);
    }
  }

  componentWillUnmount() {
    clearTimeout(impressionDebounceTimeoutId);
  }

  handleActiveQueryTypeUpdate = (newType) => {
    if (!newType) return;
    this.setState({
      activeEventId: parseInt(newType, 10),
    });
  }

  getLocationToInitializeState = (location = '') => {
    if (location) {
      if (hasStorageItemList(LOCATION_SEARCH_COORDINATES_IN_SESSION, location)) {
        return location;
      }
    }

    return undefined;
  }

  setImpressionsSent = (impressionsSent, cb) => {
    this.setState({
      impressionsSent,
    }, cb);
  }

  // called from PlacesTypeahead
  handleLocationSelect = ({
    /* eslint-disable camelcase */
    location,
    ne_lat,
    ne_lng,
    sw_lat,
    sw_lng,
    coords,
    locationType,
    placeId,
    /* eslint-enable camelcase */
  }) => {
    // Get new map bounds
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend({ lat: ne_lat, lng: sw_lng });
    bounds.extend({ lat: sw_lat, lng: ne_lng });
    // Update query
    const query = {
      ...parseBounds(bounds),
      location,
      coords,
      locationType,
      placeId,
    };

    // these search params are set into state ready to submit when handleSearchSubmit() is called.
    this.setState({ ...query });
  };

  handleMyLocationSelect = async (e) => {
    if (e) e.preventDefault();
    try {
      const position = await getUserLocationAsync();
      // Create some bounds, and fit the user location to it
      const bounds = new google.maps.LatLngBounds();
      const mapDimensions = document.querySelector('.Map').getBoundingClientRect();
      const { mapConfiguration } = this.props.configuration;
      const myLocationZoom = mapConfiguration?.myLocationSearchZoom ?? 10;
      // We can't control the zoom directly when creating bounds, so we need this helper
      // to generate padding points with a center point and a given zoom level
      const paddingPoints = getBoundsFromCenterAndZoom(position, myLocationZoom, mapDimensions);
      paddingPoints.forEach((point) => bounds.extend(point));
      const locationText = MY_LOCATION_SEARCH_KEY;

      // Store this in the previous searches area in session
      setStorageItemList(LOCATION_SEARCH_COORDINATES_IN_SESSION, locationText, position);

      this.setState({
        ...parseBounds(bounds),
        coords: position,
        location: locationText,
        locationType: null,
        placeId: null,
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  handleNearby = () => {
    const { coords } = this.state;
    const { tours } = this.props;
    if (coords) {
      const nearest = nearestTours({ tours, coords });
      if (nearest.length) {
        const bounds = new window.google.maps.LatLngBounds();
        nearest.forEach((nearestTour) => {
          bounds.extend({
            lat: nearestTour.location?.lat,
            lng: nearestTour.location?.lon,
          });
        });
        updateRouterQuery(routes.sites, {
          ...parseBounds(bounds),
          searchBy: searchByType.LOCATION,
          zoomLevel: null,
        });
      }
    }
  }

  handleDurationSelect = (duration) => {
    this.setState({
      duration,
    });
  }

  handleDepartureMonthSelect = (departureMonth) => {
    this.setState({
      departureMonth,
    });
  }

  // TODO, don't think this is being used.
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleChangeSearch = (types) => {
    if (this.props.isMobileMapOpen) {
      this.props.toggleMobileMap();
    }
    scrollToTop();
    // Preserve query state
    const query = {
      ...this.props.query,
      // this must be preserved to allow the widget to pass state
      searchBy: this.props.query.searchBy,
      campsiteId: '',
      showResults: false,
      ukItinerary: types ? '' : this.props.query.ukItinerary,
    };

    if (types && Array.isArray(types)) {
      query.types = types;
    }

    return this.props.onClear(null, query);
  }

  handleLocationChange = ({ location }) => {
    const query = {
      location,
      ne_lat: null,
      ne_lng: null,
      sw_lat: null,
      sw_lng: null,
    };

    updateRouterQuery(routes.sites, {
      ...this.props.query,
      location,
    });

    this.setState({ error: null, ...query });
  }

  handleSearchByToggle = (event) => {
    const { value } = event.target;

    this.setState({
      error: null,
    });

    // Set whether current search is Campsite or Location in parent component, so we can persist it
    this.props.updateSearchBy(value);
  }

  handleDateChange = (value) => {
    const [start, end] = value;
    const query = { ...this.props.query };

    query.start = start ? format(new Date(start), DATE_FORMAT_DEFAULT) : '';
    query.end = end ? format(new Date(end), DATE_FORMAT_DEFAULT) : '';

    updateRouterQuery(routes.sites, { start: query.start, end: query.end });
    this.props.onQueryChange(query);
  }

  handleClearUkItinerary = () => {
    const { defaultTypes } = this.props;
    const query = { ...this.props.query };
    if (query.location) {
      query.ukItinerary = '';
      query.types = defaultTypes;
      updateRouterQuery(routes.sites, { ukItinerary: '', types: defaultTypes });
      this.props.onQueryChange(query);
    } else {
      this.handleChangeSearch(defaultTypes);
    }
  }

  handleDateChangeByInput = (name, value) => {
    const query = { ...this.props.query };

    query[name] = value;

    if (dateInputValidationRegex.test(query.start)) {
      query.start = formatToHyphenFormat(query.start);
      updateRouterQuery(routes.sites, { start: query.start });
    } else if (dateInputValidationRegex.test(query.end)) {
      query.end = formatToHyphenFormat(query.end);
      updateRouterQuery(routes.sites, { end: query.end });
    }

    this.props.onQueryChange(query);
  }

  handleToggleTours = (isTours) => {
    const query = { ...this.props.query };
    if (isTours === query.isTours) {
      return;
    }
    this.setState({
      error: null,
    });
    updateRouterQuery(routes.sites, { isTours });
    query.isTours = isTours;
    this.props.onQueryChange(query);
  }

  handleTypeaheadChange = (value) => {
    this.setState({
      error: null,
      campsiteId: value?.id,
      campsiteName: value?.name,
    });
  }

  handleValidation = () => {
    const { searchBy } = this.props;

    /* eslint-disable camelcase */
    const {
      campsiteId,
      campsiteName,
      location,
      duration,
      departureMonth,
      ne_lat,
      ne_lng,
      sw_lat,
      sw_lng,
    } = this.state;
    const { query } = this.props;
    const isTours = query.isTours === 'true' && query.isOverseas === 'true';

    const coordsExists = !ne_lat || !ne_lng || !sw_lat || !sw_lng;

    let error = '';

    if ((!campsiteId && !campsiteName) && searchBy === searchByType.CAMPSITE) {
      error = dictionaryItem('CampsiteSearch', 'CampsiteError');
    }

    if (!isTours && (coordsExists || !location) && searchBy === searchByType.LOCATION) {
      error = dictionaryItem('CampsiteSearch', 'LocationError');
    }

    if (isTours && (!location || !duration || !departureMonth)) {
      error = dictionaryItem('CampsiteSearch', 'ToursError');
    }

    return error;
    /* eslint-enable camelcase */
  }

  handleFiltersSubmit = async (activeFilters) => {
    const { query, onQueryChange } = this.props;
    const newActiveFilters = { ...activeFilters };
    if (activeFilters.ukItinerary) {
      newActiveFilters.showResults = true;
    }
    if (query.bookingWidget === 'true' && activeFilters.ukItinerary) {
      redirectFromWidget(routes.sites, { ...newActiveFilters, showResults: true });
    } else {
      await updateRouterQuery(routes.sites, { ...newActiveFilters });
      await onQueryChange({ ...newActiveFilters });
    }
  }

  handleSearchSubmit = (cb) => {
    const error = this.handleValidation();

    if (error) {
      this.setState({ error });
      return;
    }

    const { searchBy } = this.props;

    const query = {
      ...this.props.query,
      campsiteId: this.state.campsiteId,
      campsiteName: this.state.campsiteName,
      location: this.state.location,
      method: 'replace',
      ne_lat: this.state.ne_lat,
      ne_lng: this.state.ne_lng,
      sw_lat: this.state.sw_lat,
      sw_lng: this.state.sw_lng,
      searchBy,
      siteCode: null,
      eventType: this.state.activeEventId,
      departureMonth: this.state.departureMonth,
      duration: this.state.duration,
      coords: this.state.coords,
      centerLat: this.state.coords?.lat,
      centerLng: this.state.coords?.lng,
      locationType: this.state.locationType,
      placeId: this.state.placeId,
    };

    if (searchBy === searchByType.LOCATION) {
      query.activePin = '';
      query.campsiteId = '';
      query.campsiteName = '';
      query.zoomLevel = null;
      query.location = this.state.location;
      if (query.isOverseas === 'true') {
        query.sortType = query.isTours === 'true' ? TOURS_SORT_TYPES.DISTANCE_ASC : CAMPSITE_SORT_TYPES.DISTANCE_ASC;
      }
    } else {
      query.location = '';
      query.activePin = this.state.campsiteId;
      query.campsiteId = this.state.campsiteId;
      query.campsiteName = this.state.campsiteName;
      query.zoomTo = true;
    }

    waitForCampsiteListAndPriceToUpdate = true;
    // Open info window and zoom to map marker
    this.props.onSubmitSearchForm(cb, query);
  }

  /**
   * Only push impressions when the listing has settled in updating due to the
   * map resizing becoming stable. This is quick fix, it has a shortfall if
   * a search is made and then the user moves the map within IMPRESSION_DEBOUNCE_TIME, then this
   * will wait for the map to resolve and this area will form the impression data.
   *
   * There is also logic here to ensure that impression happens after a search,
   * and not just map movement.
   */
  handleResultsUpdate = (impressionData) => {
    if (waitForCampsiteListAndPriceToUpdate || !this.state.impressionsSent) {
      if (impressionDebounceTimeoutId) {
        clearTimeout(impressionDebounceTimeoutId);
      }
      this.setImpressionsSent(true);
      impressionDebounceTimeoutId = setTimeout(() => {
        impressionDebounceTimeoutId = undefined;

        const { ukItinerary } = this.props.query ?? {};
        const impressionType = ukItinerary ?
          dataLayerManager.subCategory.UK_ITINERARY : dataLayerManager.category.SITES;
        dataLayerManager.pushImpressions(impressionType, impressionData);
        waitForCampsiteListAndPriceToUpdate = false;
      }, IMPRESSION_DEBOUNCE_TIME);
    }
  }

  handleFiltersClick = () => {
    this.setState({
      toggle: {
        ...this.state.toggle,
        filters: !this.state.toggle.filters,
      },
    });
  }

  handleResultClick = async (result) => {
    startTiming(timingTypes.CAMPSITE_CARD);
    const { id } = result;

    // Update campsiteId and activePin
    await updateRouterQuery(routes.sites, { campsiteId: id, activePin: id });
    await this.props.onQueryChange({ campsiteId: id, activePin: id });
    await this.props.onResultClick(result);

    this.props.goToTop();
  }

  handleDateClear = async () => {
    this.handleDateChange(['', '']);
  }

  handleKeyDownDate = ({ keyCode, target: { name } }) => {
    const copiedQuery = { ...this.props.query };

    if (keyCode === 8 || keyCode === 46) {
      if (name === 'startDate') {
        copiedQuery.start = '';
      } else if (name === 'endDate') {
        copiedQuery.end = '';
      }

      this.props.onQueryChange(copiedQuery);
    }
  }

  handleMobileMapToggle = () => {
    startTiming(timingTypes.MAP);
    if (this.props.query?.bookingWidget === 'true') {
      redirectFromWidget(routes.sites, {
        ...this.props.router.query,
        ...(this.props.isMobile && { showMap: true }),
      });
      return;
    }
    scrollToTop();
    this.props.toggleMobileMap();
  }

  handleEventClick = (activeEventId) => {
    if (activeEventId !== this.state.activeEventId) {
      this.setState({
        activeEventId,
      });
      const newQuery = {
        ...this.props.router.query,
        eventType: activeEventId,
      };
      const singleTypeQuery = typeof this.props.query.types === 'string';
      if (activeEventId === PLACE_EVENT_TYPES.CAMPING.id) {
        newQuery.types = singleTypeQuery ? this.props.query.types :
          this.props.query.types?.filter(
            (campsiteType) => !HIDE_FROM_CAMPING_TYPES.includes(campsiteType),
          );
      } else {
        newQuery.types = singleTypeQuery ? this.props.query.types :
          this.props.defaultTypes;
      }
      this.props.onQueryChange(newQuery);
      updateRouterQuery(routes.sites, newQuery);
    }
  }

  handleViewItinerary = (itinerary) => {
    this.setState({
      itineraryModal: itinerary,
    });
  }

  handleToggleDrawer = () => {
    this.setState({
      isDrawerVisible: !this.state.isDrawerVisible,
    });
  }

  closeGeoLocationModal = () => {
    this.setState({
      showGeolocationModal: false,
    });
  }

  openGeoLocationModal = () => {
    if (this.props.locationPermission === USER_LOCATION_PERMISSIONS.DECLINED) {
      this.setState({
        showGeolocationModal: true,
      });
      return;
    }
    // If the user hasn't specifically declined permission, try requesting
    this.requestPermission();
  }

  requestPermission = () => {
    this.closeGeoLocationModal();
    this.handleMyLocationSelect();
  }

  // eslint-disable-next-line class-methods-use-this
  renderCampsiteSearchForm(campsiteSearchFormProps) {
    return <CampsiteSearchForm {...campsiteSearchFormProps} />;
  }

  renderEventsToggle = () => {
    const query = parseQuery(this.props.query);
    const isOverseas = query.isOverseas === 'true';
    const isTours = query.isTours === 'true';
    const isukItinerary = query.ukItinerary;
    if (isOverseas || isTours || isukItinerary) {
      return null;
    }
    return (
      <Fragment>
        <Title dictionary={dictionaryItem('CampsiteSearchForm', 'EventType')} />
        <EventsButtonsWrapper
          activeId={this.state.activeEventId}
          handleButtonClick={this.handleEventClick}
          quote={this.props.quote}
          query={query}
        />
      </Fragment>
    );
  }

  renderToursToggle = (isSearchHeader) => {
    const query = parseQuery(this.props.query);
    const isOverseas = query.isOverseas === 'true';
    const isTours = query.isTours === 'true' && isOverseas;
    if (!isOverseas) {
      return null;
    }
    return (
      <ToursButtons noMargin={isSearchHeader}>
        <ToursButton
          align="center"
          textTransform="none"
          block
          active={!isTours}
          onClick={() => this.handleToggleTours('')}
          type="button"
          size="large"
          dictionary={dictionaryItem('CampsiteSearchForm', 'Overseas', 'Sites')}
        />
        <ToursButton
          align="center"
          textTransform="none"
          block
          active={isTours}
          onClick={() => this.handleToggleTours('true')}
          type="button"
          size="large"
          dictionary={dictionaryItem('CampsiteSearchForm', 'Overseas', 'Tours')}
        />
      </ToursButtons>
    );
  }

  render() {
    const query = parseQuery(this.props.query);
    const isOverseas = query.isOverseas === 'true';

    const {
      showResults, toggleBasket, suggestedUkItineraries, resizeAdjustmentAmount,
    } = this.props;

    const variables = {
      topLeftLat: query.ne_lat,
      topLeftLon: query.sw_lng,
      bottomRightLat: query.sw_lat,
      bottomRightLon: query.ne_lng,
      end: query.end,
      features: query.features,
      start: query.start,
      types: query.types,
      departureMonth: query.departureMonth,
      duration: query.duration,
      suggestedUkItineraryId: query.ukItinerary,
    };

    const searchFormProps = {
      searchError: this.state.error,
      handleChange: this.handleChange,
      handleDateClear: this.handleDateClear,
      handleFiltersClick: this.handleFiltersClick,
      handleDateChange: this.handleDateChange,
      handleDateChangeByInput: this.handleDateChangeByInput,
      handleDateInput: this.handleDateInput,
      handleKeyDownDate: this.handleKeyDownDate,
      handleLocationChange: this.handleLocationChange,
      handleLocationSelect: this.handleLocationSelect,
      handleDepartureMonthSelect: this.handleDepartureMonthSelect,
      handleDurationSelect: this.handleDurationSelect,
      handleSearchByToggle: this.handleSearchByToggle,
      handleSubmit: cb => this.handleSearchSubmit(cb),
      handleTypeaheadChange: this.handleTypeaheadChange,
      handleMyLocationSelect: this.openGeoLocationModal,
      query: {
        ...query,
        ...this.state,
        searchBy: this.props.searchBy,
      },
      searchBy: this.props.searchBy,
      toggleMobileMap: this.props.toggleMobileMap,
      campsiteId: this.state.campsiteId,
      departureMonth: this.state.departureMonth,
      duration: this.state.duration,
      onQueryChange: this.props.onQueryChange,
      locationPermission: this.props.locationPermission,
      location: this.props.location,
    };

    const isTours = query.isTours === 'true' && query.isOverseas === 'true';
    const isBookingWidget = query?.bookingWidget === 'true';

    return (
      <Fragment>
        <Filters
          onClose={this.handleFiltersClick}
          onSubmit={this.handleFiltersSubmit}
          onViewItinerary={this.handleViewItinerary}
          query={query}
          active={this.state.toggle.filters}
          suggestedUkItineraries={suggestedUkItineraries}
        />
        <ItineraryModal
          itinerary={this.state.itineraryModal}
          handleClose={() => this.handleViewItinerary(null)}
        />
        <Wrapper>
          {!showResults && !this.props.isMobileMapOpen && (
            <SearchForm formRef={this.props.formRef} router={this.props.router} sticky>
              {this.renderToursToggle()}
              {!isOverseas && !isTours && this.renderEventsToggle()}
              {!showResults &&
                <Title dictionary={dictionaryItem('CampsiteSearchForm')} marginBottom />
              }
              <CampsiteSearchForm {...searchFormProps} size="large" isTours={isTours} />
              <Text
                dictionary={dictionaryItem(
                  'CampsiteSearchSummary',
                  'PreSearch',
                  isOverseas ? 'BasedOnOverseas' : 'BasedOnUk',
                )}
              />
              {isOverseas && !isTours && !isBookingWidget && <SiteNightVoucher
                toggleBasket={toggleBasket}
                onProductTypeMismatch={this.props.onProductTypeMismatch}
                plainStyling
              />}
            </SearchForm>
          )}

          {(showResults || this.props.isMobileMapOpen) &&
            <CampsiteResults
              debounce={50}
              goToTop={this.props.goToTop}
              handleChangeSearch={this.handleChangeSearch}
              handleFiltersClick={this.handleFiltersClick}
              handleClearUkItinerary={this.handleClearUkItinerary}
              handleNearby={this.handleNearby}
              isMobileMapOpen={this.props.isMobileMapOpen}
              isMobile={this.props.isMobile}
              onQueryChange={this.props.onQueryChange}
              onResultClick={this.handleResultClick}
              onResultsUpdate={this.handleResultsUpdate}
              query={query}
              refMap={this.props.refMap}
              renderSearchForm={() => this.renderCampsiteSearchForm(searchFormProps)}
              showResults={showResults}
              toggleBasket={this.props.toggleBasket}
              toggleMobileMap={this.props.toggleMobileMap}
              variables={variables}
              onProductTypeMismatch={this.props.onProductTypeMismatch}
              renderEventsToggle={this.renderEventsToggle}
              renderToursToggle={this.renderToursToggle}
              suggestedUkItineraries={suggestedUkItineraries}
              tours={this.props.tours}
              isTours={isTours}
              searchBy={this.props.searchBy}
              coords={this.state.coords}
            />
          }

          {(this.props.isMobile || isBookingWidget) && (
            <CampsiteSearchFooter
              mapOpen={this.props.isMobileMapOpen}
              resizeAdjustmentAmount={resizeAdjustmentAmount}
            >
              <Actions showResults={!!showResults} mapOpen={this.props.isMobileMapOpen}>
                <ToggleMap onClick={this.handleMobileMapToggle} mapOpen={this.props.isMobileMapOpen} size="small">
                  <RenderIcon
                    isMobileMapOpen={this.props.isMobileMapOpen}
                    showResults={!!showResults}
                  />
                </ToggleMap>
              </Actions>
            </CampsiteSearchFooter>
          )}

          {(query.activePin && this.props.isMobileMapOpen) && (
            <FixedInfoWindow
              campsiteId={query.activePin}
              configuration={this.props.configuration}
              pitchId={(query.bookingId && query.componentId) ? query.componentId : undefined}
              campsiteName={this.props.data.currentCampsite.name}
              eventType={this.state.activeEventId}
              handleClick={this.handleResultClick}
              isOverseas={isOverseas}
              tour={this.props.tours.find(t => t.id === parseInt(query.activePin, 10))}
              isTours={isTours}
              query={query}
              lat={this.props.data.currentCampsite.lat}
              lng={this.props.data.currentCampsite.lon}
              zoomLevel={this.props.data.currentCampsite.zoomLevel}
              type={this.props.data.currentCampsite.type}
              searchVariables={variables}
            />
          )}

          {(query.activePin && this.props.isMobileMapOpen) && (
            <DisplayToRoot key={query.activePin + this.state.isDrawerVisible.toString()}>
              <DrawerInfoWindow
                campsiteId={query.activePin}
                pitchId={(query.bookingId && query.componentId) ? query.componentId : undefined}
                campsiteName={this.props.data.currentCampsite.name}
                eventType={this.state.activeEventId}
                handleClick={this.handleResultClick}
                isOverseas={isOverseas}
                tour={this.props.tours.find(t => t.id === parseInt(query.activePin, 10))}
                isTours={isTours}
                query={query}
                lat={this.props.data.currentCampsite.lat}
                lng={this.props.data.currentCampsite.lon}
                zoomLevel={this.props.data.currentCampsite.zoomLevel}
                type={this.props.data.currentCampsite.type}
                searchVariables={variables}
                isVisible={this.state.isDrawerVisible}
                handleToggleDrawer={this.handleToggleDrawer}
              />
            </DisplayToRoot>
          )}
        </Wrapper>
        <PermissionsModal
          active={this.state.showGeolocationModal}
          closeModal={this.closeGeoLocationModal}
          geoLocationInfoLink={this.props.configuration?.geoLocationPermissionsLink}
        />
      </Fragment>
    );
  }
}

export default compose(
  withRouter,
  withApollo,
  withProps(props => ({
    query: parseQueryFromRouter({ ...props.query }),
  })),
  graphql(GET_CURRENT_CAMPSITE),
  graphql(UPDATE_CURRENT_CAMPSITE, {
    props: ({ mutate }) => ({
      updateCurrentCampsite: campsiteInfo => mutate({ variables: campsiteInfo }),
    }),
  }),
  graphql(GET_SUGGESTED_UK_ITINERARIES, {
    props: ({ data }) => ({
      suggestedUkItineraries: data.UkItineraries?.suggestedUkItineraries ?? [],
    }),
  }),
  graphql(GET_CONFIGURATION, {
    props: ({
      data: {
        configuration,
      },
    }) => ({
      configuration,
    }),
  }),
)(withResizeObserver(CampsiteSearch, fixedInfoWindowClass, 50));
